<template>
  <div class="layoutContent">
    <div v-if="noDataShow" class="noDataParty">
      <div class="imgbox">
        <img
          :src="NoImg"
          alt="no data"
          srcset=""
          v-if="
            getConfigVal(curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')
          "
        />
      </div>
      <div class="noDataHtml" v-html="noData" />
    </div>
    <div v-else>
      <k-alert-msg ref="lblMsg" />
      <div class="headerTitle">
        <home-title
          :description="SettingMsg.description"
          :showpic="
            getConfigVal(curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')
          "
        />
        <div class="searchBox" :class="isWapSearch ? 'd-flex' : ''">
          <KValidationObserver ref="observer" class="searchInfo wapSearchInfo">
            <k-form-select
              :options="electionList"
              v-model="form.electionId"
              class="mr-2 mb-1"
            />
            <KValidationProvider
              class="mr-2 mb-1 w-auto"
              :rules="{
                validateDateFormat: true,
              }"
              slim
              name="fromDay"
              v-slot="{ errors }"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-datepicker
                v-model="form.fromDay"
                :aria-label="$g('dob')"
              />
            </KValidationProvider>
            <KValidationProvider
              class="mr-2 mb-1 w-auto"
              :rules="{
                validateDateFormat: true,
              }"
              slim
              name="toDay"
              v-slot="{ errors }"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-datepicker v-model="form.toDay" :aria-label="$g('dob')" />
            </KValidationProvider>

            <k-form-select
              :options="statusList"
              v-model="form.status"
              class="mr-2 mb-1 w-auto"
            />
            <div class="wapFlexShow justify-content-between">
              <k-button @click="searchMsg" variant="primary">
                buttons.search
              </k-button>
              <k-button @click="wapSearch" variant="utility">
                buttons.cancel
              </k-button>
            </div>
          </KValidationObserver>
          <div class="searchBtn wapHide">
            <k-button @click="searchMsg" variant="primary" class="mr-2">
              buttons.search
            </k-button>
            <k-button @click="clear" variant="default">
              buttons.clear
            </k-button>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between" style="margin-bottom: 10px">
        <b-icon
          icon="search"
          class="wapShow featuredFont"
          @click="wapSearch"
          style="margin-bottom: 10px"
        />
        <div class="flex-fill text-right">
          <b-button
            type="button"
            variant="primary"
            class="mr-2"
            @click="toAssignPositonPOp"
            v-permission="PW_Worker_Portal_Code.WorkAssignments.AssignPosition"
          >
            {{ $g("assignPosition") }}
          </b-button>
          <b-button
            type="button"
            variant="primary"
            class="mr-2"
            @click="toAssignWorkPOp(null)"
            v-permission="PW_Worker_Portal_Code.WorkAssignments.AssignWork"
          >
            {{ $g("assignWork") }}
          </b-button>
        </div>
      </div>
      <homeItem
        class="boxMargin"
        v-for="(itemOne, index) in model"
        :key="index"
        :item-title="itemOne.electionTitle"
        v-show="!isWapSearch"
      >
        <div
          v-for="(itemTwo, indexTwo) in itemOne.positionList"
          :key="indexTwo"
          class="tableMarginTop"
        >
          <div class="tableTitleBox">
            <div class="top">
              <div>
                <span class="mr-lg-5">
                  {{ $g("position") }}:
                  <k-link @click="toDeatil(itemOne, itemTwo)"
                    >{{ itemTwo.eleJobTitle }}
                  </k-link>
                </span>
                <div
                  class="d-lg-inline-block"
                  v-if="
                    $has(
                      PW_Worker_Portal_Code.WorkAssignments.PaymentAmount
                    )
                  "
                >
                  {{ $g("paymentAmount($)") }}:
                  {{ toThousands(itemTwo.paymentAmount) }}
                </div>
              </div>
              <div class="flex-shrink-0">
                <b-button
                  v-if="
                    itemOne.canAssignment &&
                    $has(PW_Worker_Portal_Code.WorkAssignments.AssignWork)
                  "
                  type="button"
                  variant="default"
                  @click="toAssignWorkPOp(itemOne, itemTwo)"
                >
                  {{ $g("assignWork") }}
                </b-button>
              </div>
            </div>
            <div
              class="middle"
              id="viewSupervisorContantA"
              v-if="
                itemOne.canOtherWorkersSeeAssignedWorkers &&
                getConfigVal(
                  curPageConfigAndDefaultPageConfig,
                  'viewSupervisorContantA',
                  'vIf'
                )
              "
            >
              <a
                v-if="itemTwo.placeTypeStr"
                href="javascript:void(0)"
                @click="viewSupervisorContantClick(itemOne, itemTwo)"
              >
                {{ $g("viewSupervisorContant") }}
              </a>
            </div>
            <div class="bottom">
              <div style="flex: 1">
                <span v-if="itemTwo.placeTypeStr"
                  >{{ itemTwo.placeTypeStr }}:
                  {{ itemTwo.elePollingPlace }}</span
                >
              </div>
              <div class="btnGroup">
                <b-button
                  v-if="
                    $has(PW_Worker_Portal_Code.WorkAssignments.SendAMessage) &&
                    itemTwo.placeTypeStr
                  "
                  type="button"
                  variant="default"
                  @click="sendAMessagePOP(itemOne, itemTwo)"
                >
                  {{ $g("sendAMessage") }}
                </b-button>
                <b-button
                  v-if="
                    itemTwo.displayButtons.length > 0 &&
                    itemTwo.displayButtons[0].visible &&
                    $has(PW_Worker_Portal_Code.WorkAssignments.Accept)
                  "
                  type="button"
                  variant="primary"
                  @click="toReasonPOp(1, itemTwo, true)"
                >
                  {{ $g("acceptAll") }}
                </b-button>
                <b-button
                  v-if="
                    itemTwo.displayButtons.length > 0 &&
                    itemTwo.displayButtons[1].visible &&
                    $has(PW_Worker_Portal_Code.WorkAssignments.Decline)
                  "
                  type="button"
                  variant="utility"
                  @click="toReasonPOp(2, itemTwo, true)"
                >
                  {{ $g("declineAll") }}
                </b-button>
                <b-button
                  v-if="
                    itemTwo.displayButtons.length > 0 &&
                    itemTwo.displayButtons[2].visible &&
                    $has(PW_Worker_Portal_Code.WorkAssignments.Withdraw)
                  "
                  type="button"
                  variant="featured"
                  @click="toReasonPOp(4, itemTwo, true)"
                >
                  {{ $g("withdrawAll") }}
                </b-button>
              </div>

              <adaptiveTable
                v-if="itemTwo.workDayList.length > 0"
                :fields="itemTwo.fields"
                :items="itemTwo.workDayList"
                class="w-100 mt-3"
              >
                <template #eleFacility="row">
                  <div v-for="(item, index) in row.value" :key="index">
                    <k-link
                      @click="onLocation(item.eleFacilityId)"
                      v-if="
                        $has(
                          PW_Worker_Portal_Code.WorkAssignments
                            .ViewFacilitiesAccessibilityPicture
                        ) && itemTwo.canAssignedWorkersSeeFacility == 2
                      "
                    >
                      {{ item.eleFacilityName }}
                    </k-link>
                    <span v-else>{{ item.eleFacilityName }}</span>
                  </div>
                </template>
                <template #eleFacilityAddress="row">
                  <div v-for="(item, index) in row.value" :key="index">
                    {{ item }}
                  </div>
                </template>
                <template #actions="row">
                  <k-link
                    v-show="
                      $has(PW_Worker_Portal_Code.WorkAssignments.Accept) &&
                      row.displayButtons.length > 0 &&
                      row.displayButtons[0].visible
                    "
                    @click="toReasonPOp(1, row, false)"
                    class="featuredFont mr-3"
                  >
                    {{ $g("Accept") }}
                  </k-link>
                  <k-link
                    v-show="
                      $has(PW_Worker_Portal_Code.WorkAssignments.Decline) &&
                      row.displayButtons.length > 0 &&
                      row.displayButtons[1].visible
                    "
                    @click="toReasonPOp(2, row, false)"
                    class="utilityFont mr-3"
                  >
                    {{ $g("Decline") }}
                  </k-link>
                  <k-link
                    v-show="
                      $has(PW_Worker_Portal_Code.WorkAssignments.Withdraw) &&
                      row.displayButtons.length > 0 &&
                      row.displayButtons[2].visible
                    "
                    @click="toReasonPOp(4, row, false)"
                    class="eventFont"
                  >
                    {{ $g("Withdraw") }}
                  </k-link>
                </template>
              </adaptiveTable>
            </div>
          </div>
        </div>
      </homeItem>
      <div class="pop">
        <k-popwindow
          id="modal-assignPosition"
          @ok="onOKAssignPosition"
          :before-close="onBeforeCloseAssignPosition"
          :check-selected="false"
          :title="$g('assignPosition')"
        >
          <AssignPositionPopWindow ref="AssignPosition" />
        </k-popwindow>
        <k-popwindow
          id="modal-assignWork"
          :check-selected="false"
          @ok="onOKAssignWorker"
          :before-close="onBeforeCloseAssignWorker"
          :title="$g('assignWork')"
        >
          <AssignWorkerPopWindow
            ref="AssignWorkerPopWindow"
            :props-data="assignWorkerPropsData"
            @show-message="showmessage"
          />
        </k-popwindow>
        <k-popwindow
          id="modal-addAssignWork"
          :check-selected="false"
          @ok="onOKAddAssignWorker"
          :before-close="onBeforeCloseAddAssignWorker"
          :title="$g('assignWork')"
        >
          <AddAssignWorkerPopWindow
            ref="addAssignWorkerPopWindow"
            @show-message="showmessage"
          />
        </k-popwindow>
        <k-popwindow
          id="modal-accept"
          @ok="onOKSelectReason"
          :before-close="onSelectReasonBeforeClose"
          :check-selected="false"
          :title="ctFun.reasonTitle"
        >
          <SelectReason ref="selectReason" :func="ctFun" />
        </k-popwindow>
        <k-popwindow
          hide-footer
          :title="$g('message')"
          :id="sendAMessagePOPId"
          @shown="shown"
        >
          <send-a-message
            :pop-id="sendAMessagePOPId"
            :props-data="sendAMessagePropsData"
            @sendSuccess="sendAMessageSuccess"
          />
        </k-popwindow>
        <k-popwindow
          id="modal-photoList"
          :hide-ok="true"
          :cancel-title="$g('popwindow.close')"
          :title="$g('facilitiesAccessibilityInformation')"
        >
          <PhotoList :ele-facility-id="popEleFacilityId" />
        </k-popwindow>
        <k-popwindow
          id="modal-viewSupervisorContant"
          :before-close="null"
          :check-selected="false"
          :hide-ok="true"
          :hide-footer="true"
          :title="$g('viewSupervisorContant')"
        >
          <viewSupervisorContantPopwindow
            :election-id="viewSupervisorContantPopwindowData.electionId"
            :ele-job-id="viewSupervisorContantPopwindowData.eleJobId"
            :ele-polling-place-id="
              viewSupervisorContantPopwindowData.elePollingPlaceId
            "
            :showpic="
              getConfigVal(curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')
            "
          />
        </k-popwindow>
      </div>
    </div>
  </div>
</template>

<script>
const functionType = {
  unknown: 0,
  changeStatus: 1,
  add: 2,
  accept: 4,
  reject: 8,
  delete: 16,
  addToShortList: 32,
  assignPosition: 64,
  assignToTraining: 128,
  assignWork: 256,
};
import NoImg from "@/assets/image/noData.png";
import PhotoList from "./components/photoList";
import homeItem from "./components/home-item.vue";
import AssignPositionPopWindow from "./components/assignPosition.vue";
import AddAssignWorkerPopWindow from "./components/addAssignWork.vue";
import viewSupervisorContantPopwindow from "./components/viewSupervisorContantPopwindow.vue";
import AssignWorkerPopWindow from "./components/assignWorker.vue";
import SelectReason from "./components/selectReason.vue";
import SendAMessage from "./components/send-a-message.vue";
import {
  getPositions,
  getElectionList,
  getStatusList,
  getShownodata,
  checkReasonsType,
  multiConfirmStatus,
  oneConfirmStatus,
  saveAssignment,
  saveAssignmentMulti,
} from "@/api/workAssignments";
import {
  g,
  repResult,
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
  storeage,
} from "fe-infrastractures";

import { getPortalPageSetting } from "@/api/home";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import adaptiveTable from "./components/adaptive-table.vue";
import { PW_Worker_Portal_Code } from "../../router/permissionCode";
import HomeTitle from "@/views/components/homeTitle";

export default {
  components: {
    homeItem,
    AssignPositionPopWindow,
    AssignWorkerPopWindow,
    SelectReason,
    adaptiveTable,
    AddAssignWorkerPopWindow,
    HomeTitle,
    SendAMessage,
    PhotoList,
    viewSupervisorContantPopwindow,
  },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      NoImg,
      SettingMsg: {},
      savestatus: 0,
      savemessage: "",
      form: {
        electionId: null,
        fromDay: "",
        toDay: "",
        status: null,
      },
      electionList: [],
      statusList: [],
      noDataShow: false,
      PW_Worker_Portal_Code,
      model: [],
      noData: null,
      fields: [
        {
          key: "workDay",
          label: g("day"),
        },
        {
          key: "fromTime",
          label: g("startTime"),
        },
        {
          key: "toTime",
          label: g("endTime"),
        },
        {
          key: "eleFacility",
          label: g("workLocation"),
        },
        {
          key: "eleFacilityAddress",
          label: g("address"),
        },
        {
          key: "action",
          label: g("action"),
        },
      ],
      ctFun: {},
      assignWorkerPropsData: {},
      isWapSearch: false,
      sendAMessagePOPId: "modal-send-a-message",
      sendAMessagePropsData: {},
      sendAMessageResult: {},
      popEleFacilityId: null,
      viewSupervisorContantPopwindowData: {
        electionId: "",
        eleJobId: "",
        elePollingPlaceId: "",
      },
    };
  },
  watch: {
    "form.fromDay"(newValue, oldValue) {
      if (newValue == -2208988800000) {
        this.form.fromDay = "";
      }
    },
    "form.toDay"(newValue, oldValue) {
      if (newValue == -2208988800000) {
        this.form.toDay = "";
      }
    },
  },
  mounted() {
    this.getPositions();
  },
  methods: {
    onLocation(id) {
      this.popEleFacilityId = id;
      this.$bvModal.show("modal-photoList");
    },
    toDeatil(itemOne, item) {
      this.$router.push({
        name: "workAssignment-detail",
        query: {
          electionId: itemOne.electionId,
          eleJobId: item.eleJobId,
          elePollingPlaceId: item.elePollingPlaceId,
          type: item.placeType,
        },
      });
    },
    toThousands(num) {
      return num
        .toFixed(storeage.getPayrollTotalAmountDigits())
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    },
    showmessage(msg) {
      this.$refs.lblMsg.message(msg.status, msg.info);
    },
    toAssignPositonPOp() {
      this.$bvModal.show("modal-assignPosition");
    },
    toAssignWorkPOp(itemOne, item) {
      if (itemOne && item) {
        this.assignWorkerPropsData = {
          ...item,
          electionId: itemOne.electionId,
          isPlainText: true,
        };
        this.$bvModal.show("modal-assignWork");
      } else {
        this.$bvModal.show("modal-addAssignWork");
      }
    },
    async toReasonPOp(type, item, muiti) {
      this.ctFun = {};
      if (type == 1) {
        this.ctFun.reasonTitle = g("acceptReason");
      } else if (type == 2) {
        this.ctFun.reasonTitle = g("declinedReason");
      } else if (type == 4) {
        this.ctFun.reasonTitle = g("withdrawReason");
      }
      let res = await checkReasonsType({ reasonType: type });
      this.ctFun = {
        muiti: muiti,
        ...res.data,
        ...item,
        type: type,
        ...this.ctFun,
      };
      if (!res.data.reasonsRequired) {
        const msg = this.$g("selectReasonConfirm").replace(
          "{type}",
          this.ctFun.reasonTitle
        );
        return this.$bvModal
          .msgBoxConfirm(msg, {
            title: this.$g("const.confirm.confirm"),
            cancelTitle: this.$g("const.confirm.yes"),
            okTitle: this.$g("const.confirm.no"),
            cancelVariant: "primary",
            okVariant: "secondary",
            noCloseOnBackdrop: true,
          })
          .then((value) => {
            if (!value) {
              let data = {
                elePollingPlaceId: this.ctFun.elePollingPlaceId,
                eleJobTitleAssignmentId: this.ctFun.eleJobTitleAssignmentId,
                eleJobId: this.ctFun.eleJobId,
                confirmStatus: this.ctFun.type,
              };
              this.callFunction(data);
            }
          });
      }
      this.$bvModal.show("modal-accept");
    },

    onOKAssignPosition() {
      this.$refs.AssignPosition.saveData().then((res) => {
        this.$refs.lblMsg.message(res.data.status, res.message);
        if (res.data.status == repResult.success) {
          this.getallMsg();
        }
      });
    },
    async onBeforeCloseAssignPosition() {
      const valid = await this.$refs.AssignPosition.saveAction();
      if (valid) {
        return this.$bvModal
          .msgBoxConfirm(this.$g("const.confirm.save"), {
            title: this.$g("const.confirm.confirm"),
            cancelTitle: this.$g("const.confirm.saveYes"),
            okTitle: this.$g("const.confirm.no"),
            cancelVariant: "primary",
            okVariant: "secondary",
            noCloseOnBackdrop: true,
          })
          .then((value) => {
            return value != null && !value;
          })
          .catch((err) => {
            return false;
          });
      } else {
        return false;
      }
    },
    onOKAddAssignWorker() {
      this.$refs.lblMsg.message(this.savestatus, this.savemessage);
      this.getallMsg();
    },
    async onBeforeCloseAddAssignWorker() {
      let valid = await this.$refs.addAssignWorkerPopWindow.checkValid();

      if (valid) {
        let confirm = await this.$bvModal.msgBoxConfirm(
          this.$g("const.confirm.save"),
          {
            title: this.$g("const.confirm.confirm"),
            cancelTitle: this.$g("const.confirm.saveYes"),
            okTitle: this.$g("const.confirm.no"),
            cancelVariant: "primary",
            okVariant: "secondary",
            noCloseOnBackdrop: true,
          }
        );
        if (!confirm) {
          let params = this.$refs.addAssignWorkerPopWindow.getFormData();
          let api = saveAssignment;
          if (this.$refs.addAssignWorkerPopWindow.multiAssignmentPosition) {
            api = saveAssignmentMulti;
          }
          let res = await api(params);
          if (res.data.status == repResult.success) {
            this.savestatus = res.data.status;
            this.savemessage = res.message;
            return true;
          } else if (res.data.status == repResult.faild) {
            this.$refs.addAssignWorkerPopWindow.setMessage(res.message);
            return false;
          }
        }
      }
      return false;
    },
    async onBeforeCloseAssignWorker() {
      let valid = await this.$refs.AssignWorkerPopWindow.checkValid();
      if (valid) {
        let confirm = await this.$bvModal.msgBoxConfirm(
          this.$g("const.confirm.save"),
          {
            title: this.$g("const.confirm.confirm"),
            cancelTitle: this.$g("const.confirm.saveYes"),
            okTitle: this.$g("const.confirm.no"),
            cancelVariant: "primary",
            okVariant: "secondary",
            noCloseOnBackdrop: true,
          }
        );
        if (!confirm) {
          let params = this.$refs.AssignWorkerPopWindow.getFormData();
          let api = saveAssignment;
          if (this.$refs.AssignWorkerPopWindow.multiAssignmentPosition) {
            api = saveAssignmentMulti;
          }
          let res = await api(params);
          if (res.data.status == repResult.success) {
            this.savestatus = res.data.status;
            this.savemessage = res.message;
            return true;
          } else if (res.data.status == repResult.faild) {
            this.$refs.AssignWorkerPopWindow.setMessage(res.message);
            return false;
          }
        }
      }
      return false;
    },
    onOKAssignWorker() {
      this.getallMsg();
      this.$refs.lblMsg.message(this.savestatus, this.savemessage);
    },
    async getPositions() {
      let { data } = await getShownodata();
      this.noData = data;
      if (!data) {
        getPortalPageSetting(pageNameEnum.WorkAssignmentList).then((res) => {
          this.SettingMsg = res.data;
        });
        this.getSelectMsg();
        this.getallMsg();
      } else {
        this.noDataShow = true;
      }
    },
    async searchMsg() {
      let pmOpt = await this.$refs.observer.validate();
      this.isWapSearch = false;
      if (!pmOpt) {
        return;
      }
      this.getallMsg();
    },
    getallMsg() {
      getPositions(this.form).then((res) => {
        this.model = JSON.parse(JSON.stringify(res.data));
        this.model.forEach((ele) => {
          if (!ele.isMultiConfirm) {
            ele.positionList.forEach((element) => {
              if (
                element.placeType == 4 ||
                element.placeType == 8 ||
                element.placeType == 32
              ) {
                element.fields = this.fields;
              } else {
                element.fields = [
                  {
                    key: "workDay",
                    label: g("day"),
                  },
                  {
                    key: "fromTime",
                    label: g("startTime"),
                  },
                  {
                    key: "toTime",
                    label: g("endTime"),
                  },
                  {
                    key: "action",
                    label: g("action"),
                  },
                ];
              }
            });
          } else {
            ele.positionList.forEach((element) => {
              if (
                element.placeType == 4 ||
                element.placeType == 8 ||
                element.placeType == 32
              ) {
                element.fields = [
                  {
                    key: "workDay",
                    label: g("day"),
                  },
                  {
                    key: "fromTime",
                    label: g("startTime"),
                  },
                  {
                    key: "toTime",
                    label: g("endTime"),
                  },
                  {
                    key: "eleFacility",
                    label: g("workLocation"),
                  },
                  {
                    key: "eleFacilityAddress",
                    label: g("address"),
                  },
                ];
              } else {
                element.fields = [
                  {
                    key: "workDay",
                    label: g("day"),
                  },
                  {
                    key: "fromTime",
                    label: g("startTime"),
                  },
                  {
                    key: "toTime",
                    label: g("endTime"),
                  },
                ];
              }
            });
          }
        });
      });
    },
    getSelectMsg() {
      getElectionList().then((res) => {
        this.electionList = res.data;
      });
      getStatusList().then((res) => {
        this.statusList = res.data;
      });
    },
    onOKSelectReason() {
      let params = {
        elePollingPlaceId: this.ctFun.elePollingPlaceId,
        eleJobTitleAssignmentId: this.ctFun.eleJobTitleAssignmentId,
        eleJobId: this.ctFun.eleJobId,
        confirmWorkReasonId: this.$refs.selectReason.getReasonId(),
        reasonComment: this.$refs.selectReason.getReason(),
        confirmStatus: this.ctFun.type,
      };
      this.callFunction(params);
    },
    async onSelectReasonBeforeClose() {
      let valid = await this.$refs.selectReason.checkValid();
      const msg = this.$g("selectReasonConfirm").replace(
        "{type}",
        this.ctFun.reasonTitle
      );
      if (valid) {
        return this.$bvModal
          .msgBoxConfirm(msg, {
            title: this.$g("const.confirm.confirm"),
            cancelTitle: this.$g("const.confirm.yes"),
            okTitle: this.$g("const.confirm.no"),
            cancelVariant: "primary",
            okVariant: "secondary",
            noCloseOnBackdrop: true,
          })
          .then((value) => {
            return value != null && !value;
          });
      }
      return false;
    },
    callFunction(data) {
      let callApi;
      if (this.ctFun.muiti) {
        callApi = multiConfirmStatus;
      } else {
        callApi = oneConfirmStatus;
      }
      callApi(data).then((res) => {
        this.$refs.lblMsg.message(res.data.status, res.data.info);
        if (res.data.status == repResult.success) {
          this.getallMsg();
        }
      });
    },
    wapSearch() {
      this.isWapSearch = !this.isWapSearch;
    },
    clear() {
      this.form.electionId = null;
      this.form.fromDay = "";
      this.form.toDay = "";
      this.form.status = null;
      this.getallMsg();
    },
    sendAMessagePOP(itemOne, item) {
      this.sendAMessagePropsData = {
        ...item,
        electionId: itemOne.electionId,
      };
      this.$bvModal.show(this.sendAMessagePOPId);
    },
    sendAMessageSuccess(res) {
      this.$refs.lblMsg.message(res.data.status, res.message);
    },
    viewSupervisorContantClick(itemOne, itemTwo) {
      this.viewSupervisorContantPopwindowData.electionId = itemOne.electionId;
      this.viewSupervisorContantPopwindowData.eleJobId = itemTwo.eleJobId;
      this.viewSupervisorContantPopwindowData.elePollingPlaceId =
        itemTwo.elePollingPlaceId;
      this.$bvModal.show("modal-viewSupervisorContant");
    },
    shown() {
      var modalBody = document.getElementById(
        "modal-send-a-message___BV_modal_body_"
      );
      if (modalBody) {
        modalBody.style.position = "unset";
        modalBody.style.marginBottom = "63px";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.layoutContent {
  .headerTitle {
    margin-bottom: 10px;
  }

  .boxMargin {
    .tableTitleBox {
      a {
        display: inline-block;
      }

      .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        align-items: center;
      }

      .middle {
        margin-bottom: 1rem;
      }

      .bottom {
        display: flex;
        margin-bottom: 20px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .btnGroup {
          text-align: right;

          .btn:nth-child(n + 2) {
            margin-left: 1rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .tableTitleBox .bottom .btnGroup {
    order: 1;
    width: 100%;
    margin-top: 1rem;

    .btn {
      margin-bottom: 0.5rem;
    }
  }
}
</style>

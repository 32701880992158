<template>
  <div>
    <b-table bordered :fields="fields" :items="items" class="wapHide">
      <template v-slot:table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'action' ? '20%' : 'auto' }"
        />
      </template>
      <template v-for="field in fields" #[cellSlotName(field)]="row">
        <slot :name="field.key" v-bind="row" v-if="field.key != 'action'">
          {{ row.value }}
        </slot>
        <slot v-else name="actions" v-bind="row.item"></slot>
      </template>
    </b-table>
    <div class="wapModel layoutTableCard">
      <div v-for="item in items" :key="item.id" class="tableGroup">
        <div class="tableList">
          {{ item.workDay }} {{ item.fromTime }} - {{ item.toTime }}
        </div>
        <template v-for="(item2, index) in fields">
          <div
            :key="index"
            class="tableList"
            v-if="item2.key == 'eleFacility'"
            style="flex-wrap: nowrap ;"
          >
            <div class="control-field" style="flex-shrink: 0">{{ item2.label }}:&nbsp;</div>
            <div class="control-value">
              <template v-if="Array.isArray(item[item2.key])">
                <slot name="eleFacility" v-bind="{ value: item.eleFacility }"></slot>
              </template>
              <template v-else>
                {{ item[item2.key] }}
              </template>
            </div>
          </div>
          <div :key="index" v-if="item2.key == 'action'" style="flex: 1">
            <slot name="actions" v-bind="item"></slot>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    fields: {
      type: Array,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
  },
  methods: {
    cellSlotName(fieldName) {
      return "cell(" + fieldName.key + ")";
    },
  },
};
</script>
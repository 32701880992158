<template>
  <KValidationObserver ref="observer">
    <k-alert-msg ref="lblMsg" />
    <p>{{ title }}</p>
    <k-form-group
      label-suffix="totalFilledTarget"
      label-for="noneBind"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <div
        class="col-form-label"
        v-if="
          assignmentTarget.unit ==
          positionFormulaUnit.assignmentsOverallCompletePeriod
        "
      >
        <span
          :class="
            totalFilledComplete >= assignmentTarget.target ? 'text-green' : 'text-red'
          "
        >
          {{ totalFilledComplete }}
        </span>
        /
        {{
          assignmentTarget.unit == positionFormulaUnit.hoursOverall ||
          assignmentTarget.unit == positionFormulaUnit.hoursPerDay
            ? assignmentTarget.target.toFixed(2)
            : assignmentTarget.target
        }}
        {{ assignmentTarget.unitShowTypeStr }}
      </div>
      <div class="col-form-label" v-else>
        <span
          :class="
            totalFilled >= assignmentTarget.target ? 'text-green' : 'text-red'
          "
        >
          {{ totalFilled }}
        </span>
        /
        {{
          assignmentTarget.unit == positionFormulaUnit.hoursOverall ||
          assignmentTarget.unit == positionFormulaUnit.hoursPerDay
            ? assignmentTarget.target.toFixed(2)
            : assignmentTarget.target
        }}
        {{ assignmentTarget.unitShowTypeStr }}
      </div>
    </k-form-group>
    <k-form-group
      label-class="require-mark"
      label-suffix="workDateTime"
      label-for="noneBind"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="9"
      content-cols-md="9"
    >
      <div class="overflow-auto">
        <k-alert :show="isShowWorkDateTimeRequired" variant="warning">
          {{ $g("validate.required") }}
        </k-alert>
        <div
          :key="item.eleJobTitleAssignmentTargetId"
          v-for="item in assignmentTarget.workDayList"
        >
          <div
            class="d-flex align-items-center mb-2 wapSate"
            v-if="item.isCheck || item.filled < item.target"
          >
            <div>
              <k-form-checkbox
                :disabled="
                  !!(
                    item.eleJobTitleAssignmentId ||
                    !item.isEnable ||
                    assignmentTarget.unit ==
                      positionFormulaUnit.assignmentsOverallCompletePeriod
                  )
                "
                v-model="item.isCheck"
                @change="checkboxChange"
              />
            </div>
            <div class="mr-3">
              {{ item.workDate }}
            </div>
            <div class="mr-2">
              <KValidationProvider
                v-slot="{ errors }"
                :name="`startTime-${item.eleJobTitleAssignmentTargetId}`"
                :rules="
                  item.isCheck
                    ? {
                        required: true,
                        validateTimeFormat: true,
                        validateStartTimeAndEndTime: item.toTime,
                      }
                    : {}
                "
                :ref="`startTime-${item.eleJobTitleAssignmentTargetId}`"
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-timepicker
                  class="datepicker-time"
                  @onTimeChange="onStartTimeChange(item)"
                  v-model="item.fromTime"
                />
              </KValidationProvider>
            </div>
            <div class="mr-1">-</div>
            <div class="mr-2 ml-2">
              <KValidationProvider
                v-slot="{ errors }"
                :name="`endTime-${item.eleJobTitleAssignmentTargetId}`"
                :rules="
                  item.isCheck
                    ? {
                        required: true,
                        validateTimeFormat: true,
                      }
                    : {}
                "
                :ref="`endTime-${item.eleJobTitleAssignmentTargetId}`"
              >
                <k-alert :show="errors.length > 0" variant="warning">
                  {{ errors[0] }}
                </k-alert>
                <k-form-timepicker
                  class="datepicker-time"
                  @onTimeChange="onEndTimeChange(item)"
                  v-model="item.toTime"
                />
              </KValidationProvider>
            </div>
            <div class="mr-3" v-if="assignmentTarget.showHalfDay">
              <k-form-select
                v-model="item.eleJobTitleWorkDayShiftId"
                :options="workDateShiftList"
                @change="eleJobTitleWorkDayShiftChange(item)"
              />
            </div>
            <div
              v-if="
                assignmentTarget.unit != positionFormulaUnit.hoursOverall &&
                assignmentTarget.unit !=
                  positionFormulaUnit.assignmentsOverallCompletePeriod &&
                assignmentTarget.unit !=
                  positionFormulaUnit.assignmentsOverallDayToDay
              "
            >
              <span
                :class="
                  parseFloat(itemFilled(item)) >= item.target
                    ? 'text-green'
                    : 'text-red'
                "
                >{{ itemFilled(item) }}</span
              >
              /
              {{
                assignmentTarget.unit == positionFormulaUnit.hoursOverall ||
                assignmentTarget.unit == positionFormulaUnit.hoursPerDay
                  ? item.target.toFixed(2)
                  : item.target
              }}
              {{ assignmentTarget.unitShowTypeStr }}
            </div>
          </div>
        </div>
      </div>
    </k-form-group>
  </KValidationObserver>
</template>

<script>
import { repResult, storeage } from "fe-infrastractures";
export default {
  props: {
    assignmentTarget: {
      type: Object,
      default: () => {
        return { unit: null };
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
    positionFormulaUnit: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      defTotalOtherFilled: 0,
      isShowWorkDateTimeRequired: false,
      workDateShiftList: [],
    };
  },
  computed: {
    totalFilled() {
      let total = 0;
      this.assignmentTarget?.workDayList?.forEach((item) => {
        let itemFilled = parseFloat(this.itemFilled(item));
        total += itemFilled;
      });
      if (
        this.assignmentTarget.unit ==
          this.positionFormulaUnit.assignmentsPerDay ||
        this.assignmentTarget.unit ==
          this.positionFormulaUnit.assignmentsOverallDayToDay ||
        this.assignmentTarget.unit ==
          this.positionFormulaUnit.assignmentsOverallCompletePeriod
      ) {
        return total + this.defTotalOtherFilled;
      } else {
        return total + this.defTotalOtherFilled == 0
          ? 0
          : (total + this.defTotalOtherFilled).toFixed(2);
      }
    },
    getWorkLocationList() {
      return getAssignWorkWorkLocationList;
    },
    hasWorkLocation() {
      return this.formData.eleJobId && this.formData.pollingPlaceTypeId;
    },
    totalFilledComplete() {
      let total = 0;
      this.assignmentTarget?.workDayList?.some((item) => {
        let itemFilled = parseFloat(this.itemFilled(item));
        total = itemFilled;   
        return true;
      });
      return total;
    },
  },
  created() {
    this.workDateShiftList = this.assignmentTarget.halfDays;
    this.assignmentTarget?.workDayList?.forEach((item) => {
      this.$set(item, "shiftType", null);
      if (
        !!(
          item.eleJobTitleAssignmentId ||
          this.assignmentTarget.unit ==
            this.positionFormulaUnit.assignmentsOverallCompletePeriod
        )
      ) {
        this.eleJobTitleWorkDayShiftChange(item, true);
      } else {
        item.initCount = 0;
        this.workDateShiftList.forEach((it) => {
          if (item.eleJobTitleWorkDayShiftId == it.value) {
            item.shiftType = it.label;
          }
        });
      }
    });
    this.defTotalOtherFilled =
      this.assignmentTarget.filled -
      this.itemFilled(this.assignmentTarget.workDayList[0]);
  },
  methods: {
    eleJobTitleWorkDayShiftChange(item, first = false) {
      this.workDateShiftList.forEach((it) => {
        if (item.eleJobTitleWorkDayShiftId == it.value) {
          if (it.relateContent && !first) {
            item.fromTime = it.relateContent;
          }
          if (it.relateContent2 && !first) {
            item.toTime = it.relateContent2;
          }
          item.shiftType = it.label;
          if (first) {
            if (it.label != "1") {
              //Shift Type no Full Day
              item.initCount = 0.5;
            } else {
              //Shift Type is Full Day
              item.initCount = 1;
            }
          } else {
            this.itemFilled(item);
          }
          if(this.assignmentTarget.unit ==
            this.positionFormulaUnit.assignmentsOverallCompletePeriod){
            this.assignmentTarget?.workDayList?.forEach((item2) => {
              if(item2.eleJobTitleWorkDayShiftId != it.value){
                item2.eleJobTitleWorkDayShiftId = it.value
                if (it.relateContent) {
                  item2.fromTime = it.relateContent;
                }
                if (it.relateContent2) {
                  item2.toTime = it.relateContent2;
                }
                item2.shiftType = it.label;
                if (first) {
                  if (it.label != "1") {
                    //Shift Type no Full Day
                    item2.initCount = 0.5;
                  } else {
                    //Shift Type is Full Day
                    item2.initCount = 1;
                  }
                } else {
                  this.itemFilled(item2);
                }
              }
              if(!item2.eleJobTitleAssignmentId){
                // no eleJobTitleAssignment unallocated
                item2.initCount = 0
              }
            });
          }
        }
      });
    },
    checkboxChange(val) {
      if (val) {
        this.isShowWorkDateTimeRequired = false;
      } else {
        if (
          this.assignmentTarget.workDayList.filter((item) => item.isCheck)
            .length == 0
        ) {
          this.isShowWorkDateTimeRequired = true;
        }
      }
    },
    onStartTimeChange(item) {
      this.$refs[`endTime-${item.eleJobTitleAssignmentTargetId}`][0].validate();
    },
    onEndTimeChange(item) {
      setTimeout(() => {
        this.$refs[
          `startTime-${item.eleJobTitleAssignmentTargetId}`
        ][0].validate();
      }, 100);
    },
    itemFilled(item) {
      let canAdd =
        item.isCheck &&
        item.isValid &&
        item.fromTime &&
        item.toTime &&
        item.fromTime != this.INVALID_DATE &&
        item.toTime != this.INVALID_DATE &&
        item.toTime > item.fromTime;
      if (
        this.assignmentTarget.unit == this.positionFormulaUnit.hoursPerDay ||
        this.assignmentTarget.unit == this.positionFormulaUnit.hoursOverall
      ) {
        return (
          canAdd
            ? item.filled -
              item.eleJobTitleAssignmentFilled +
              (item.toTime - item.fromTime) / 3600000
            : item.filled
        ).toFixed(2);
      } else {
        //Shift Type is Full Day
        let conut = 1;
        if (item.shiftType != "1") {
          //Shift Type no Full Day
          conut = 0.5;
        }
        return canAdd ? item.filled - item.initCount + conut : item.filled;
      }
    },
    checkValidDetial() {
      let isOverTheTarget = false;
      if (
        this.assignmentTarget.unit == this.positionFormulaUnit.hoursPerDay ||
        this.assignmentTarget.unit == this.positionFormulaUnit.hoursOverall
      ) {
        this.assignmentTarget.workDayList.forEach((item) => {
          if (
            item.isValid &&
            item.isCheck &&
            item.filled -
              item.eleJobTitleAssignmentFilled +
              (item.toTime - item.fromTime) / 3600000 >
              item.target
          ) {
            this.$refs.lblMsg.message(
              repResult.faild,
              this.$g("overTheTarget")
            );
            isOverTheTarget = true;
          }
        });
      } else {
        if (parseFloat(this.totalFilled) > this.assignmentTarget.target) {
          this.$refs.lblMsg.message(repResult.faild, this.$g("overTheTarget"));
          isOverTheTarget = true;
        }
      }
      if (isOverTheTarget) {
        return false;
      } else {
        return this.$refs.observer.validate();
      }
    },
  },
};
</script>

<style>
</style>
